// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10834(d3505f2c7e)-C31/10/2024-07:29:11-B31/10/2024-07:32:56' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10834(d3505f2c7e)-C31/10/2024-07:29:11-B31/10/2024-07:32:56",
  branch: "master",
  latestTag: "6.1",
  revCount: "10834",
  shortHash: "d3505f2c7e",
  longHash: "d3505f2c7e7d53c0a918d96803336ab37cafc5b5",
  dateCommit: "31/10/2024-07:29:11",
  dateBuild: "31/10/2024-07:32:56",
  buildType: "Ansible",
  } ;
